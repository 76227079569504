import { User } from "@/graphql/generated/types";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import Link from "next/link";
import { usePathname } from "next/navigation";
const MobileBottomNavbar = ({
  user
}: {
  user: {
    id?: User["id"];
    displayName?: User["displayName"];
    username?: User["username"];
    avatarId?: User["avatarId"];
  } | null;
}) => {
  // const { user } = useUser()
  const pathname = usePathname();
  // const { data: usernameData } = useQuery(MyUsernameDocument, {
  //   skip: !user,
  // })

  const username = user?.username;
  const showNavbar = ["/", `/users/${username}/library`, `/users/${username}`].some(path => pathname === `${path}` || pathname.startsWith(`${path}/`));
  if (!showNavbar) return null;
  const isActive = (path: string) => pathname === `${path}`;
  return <nav style={{
    filter: "drop-shadow(0px -0.5px 0px rgba(0, 0, 0, 0.3)"
  }} className="fixed bottom-0 left-0 right-0 z-30 min-h-[49px]  grid grid-cols-3 bg-[#1d1e2f] md:hidden" data-sentry-component="MobileBottomNavbar" data-sentry-source-file="MobileBottomNavbar.tsx">
      <NavItem href="/" isActive={isActive("/")} label="Home" data-sentry-element="NavItem" data-sentry-source-file="MobileBottomNavbar.tsx">
        <HomeIcon isActive={isActive("/")} data-sentry-element="HomeIcon" data-sentry-source-file="MobileBottomNavbar.tsx" />
      </NavItem>
      <NavItem href={username ? `/users/${username}/library` : "/login"} isActive={isActive(`/users/${username}/library`)} label="Library" data-sentry-element="NavItem" data-sentry-source-file="MobileBottomNavbar.tsx">
        <LibraryIcon isActive={isActive(`/users/${username}/library`)} data-sentry-element="LibraryIcon" data-sentry-source-file="MobileBottomNavbar.tsx" />
      </NavItem>
      <NavItem href={username ? `/users/${username}` : "/login"} isActive={isActive(`/users/${username}`)} label="Profile" data-sentry-element="NavItem" data-sentry-source-file="MobileBottomNavbar.tsx">
        <ProfileIcon isActive={isActive(`/users/${username}`)} data-sentry-element="ProfileIcon" data-sentry-source-file="MobileBottomNavbar.tsx" />
      </NavItem>
    </nav>;
};
export default MobileBottomNavbar;
const NavItem = ({
  href,
  isActive,
  label,
  children
}: {
  href: string;
  isActive: boolean;
  label: string;
  children: React.ReactNode;
}) => <Link href={href ?? ""} className={cn("flex pt-1.5 pb-0.5 flex-col gap-1 items-center")} data-sentry-element="Link" data-sentry-component="NavItem" data-sentry-source-file="MobileBottomNavbar.tsx">
    <motion.div initial={{
    scale: 1
  }} whileTap={{
    scale: 0.85
  }} animate={{
    scale: isActive ? 1.1 : 1
  }} transition={{
    type: "spring"
  }} data-sentry-element="unknown" data-sentry-source-file="MobileBottomNavbar.tsx">
      {children}
    </motion.div>
    <span className={cn("text-[10px] font-medium", isActive ? "text-[#5069ce]" : "text-[#e0e0e0]")}>
      {label}
    </span>
  </Link>;
const HomeIcon = ({
  isActive
}: {
  isActive: boolean;
}) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
// className={
//   isActive
//     ? "transition-transform duration-100 scale-110 fill-[#5069ce]"
//     : "fill-white"
// }
data-sentry-element="svg" data-sentry-component="HomeIcon" data-sentry-source-file="MobileBottomNavbar.tsx">
    <path fillRule="evenodd" clipRule="evenodd" d="M20.29 7.00048L14.73 2.89048C13.1036 1.70317 10.8964 1.70317 9.27 2.89048L3.72 7.00048C2.64544 7.77462 2.0061 9.01612 2 10.3405V17.7705C2.06002 20.1637 4.04665 22.0564 6.44 22.0005H17.56C19.9534 22.0564 21.94 20.1637 22 17.7705V10.3305C21.9914 9.01185 21.3567 7.77576 20.29 7.00048ZM20.5 17.7705C20.4404 19.3354 19.1251 20.5568 17.56 20.5005H6.44C4.87698 20.5512 3.56502 19.333 3.5 17.7705V10.3405C3.50534 9.4904 3.91817 8.69448 4.61 8.20048L10.16 4.10048C11.2561 3.30006 12.7439 3.30006 13.84 4.10048L19.39 8.21048C20.0812 8.6959 20.4948 9.48583 20.5 10.3305V17.7705ZM7.5 15.7505H16.5C16.9142 15.7505 17.25 16.0863 17.25 16.5005C17.25 16.9147 16.9142 17.2505 16.5 17.2505H7.5C7.08579 17.2505 6.75 16.9147 6.75 16.5005C6.75 16.0863 7.08579 15.7505 7.5 15.7505Z" fill={isActive ? "#5069ce" : "white"} data-sentry-element="path" data-sentry-source-file="MobileBottomNavbar.tsx" />
  </svg>;
const LibraryIcon = ({
  isActive
}: {
  isActive: boolean;
}) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={isActive ? "#5069ce" : "white"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg"
// className={
//   isActive ? "transition-transform scale-110 fill-[#5069ce]" : "fill-white"
// }
data-sentry-element="svg" data-sentry-component="LibraryIcon" data-sentry-source-file="MobileBottomNavbar.tsx">
    <path fillRule="evenodd" clipRule="evenodd" d="M20.26 15.844V7.07143C20.26 4.82284 18.5811 3 16.51 3H7.75C5.67893 3 4 4.82284 4 7.07143V18.5149C4 20.4396 5.43717 22 7.21 22H19.51C19.9242 22 20.26 21.6354 20.26 21.1857C20.26 20.736 19.9242 20.3714 19.51 20.3714H7.21C6.26559 20.3714 5.5 19.5402 5.5 18.5149C5.5 17.4895 6.26559 16.6583 7.21 16.6583H19.51C19.9242 16.6583 20.26 16.2937 20.26 15.844Z" fill="none" data-sentry-element="path" data-sentry-source-file="MobileBottomNavbar.tsx" />
  </svg>;
const ProfileIcon = ({
  isActive
}: {
  isActive: boolean;
}) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={isActive ? "#5069ce" : "white"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg"
// className={
//   isActive ? "transition-transform scale-110 fill-[#5069ce]" : "fill-white"
// }
data-sentry-element="svg" data-sentry-component="ProfileIcon" data-sentry-source-file="MobileBottomNavbar.tsx">
    <path d="M4 20C4 18.9391 4.42143 17.9217 5.17157 17.1716C5.92172 16.4214 6.93913 16 8 16H16C17.0609 16 18.0783 16.4214 18.8284 17.1716C19.5786 17.9217 20 18.9391 20 20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20Z" fill="none" data-sentry-element="path" data-sentry-source-file="MobileBottomNavbar.tsx" />
    <path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" fill="none" data-sentry-element="path" data-sentry-source-file="MobileBottomNavbar.tsx" />
  </svg>;