import React from "react";
import { HiBell } from "react-icons/hi2";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { PopoverArrow } from "@radix-ui/react-popover";
import Link from "next/link";
// import NotificationCard from "../shared/NotificationCard"
// import Link from "next/link"
// import NotificationCard from "../shared/NotificationCard"

// const DUMMY_NOTIFICATIONS = [
//   {
//     id: 1,
//     type: "reply",
//     replyContent: `While your enthusiasm for Harry Potter and the Chamber of Secrets is evident, it's important to consider the narrative depth beyond the surface level. Rowling weaves in subtle themes of prejudice, fear of the unknown, and the power of choice, which are crucial to understanding the characters' development and the broader implications of the story. Delving into these aspects can reveal a richer, more nuanced appreciation of the book that goes beyond its magical adventure.`,
//     actors: ["Alice"],
//     target: {
//       type: "review",
//       bookTitle: "The Catcher in the Rye",
//       reviewId: 101,
//     },
//     createdAt: "2024-09-09T12:30:00Z",
//     read: false,
//   },

//   {
//     id: 2,
//     type: "reply",
//     actors: ["Bob", "Charlie"],
//     target: {
//       type: "review",
//       bookTitle: "1984",
//       reviewId: 102,
//     },
//     createdAt: "2024-09-09T11:45:00Z",
//     read: false,
//   },
//   {
//     id: 261,
//     type: "like_list",
//     actors: ["Charlie", "Diana", "Eve"],
//     target: {
//       type: "list",
//       listTitle: "Must-Read Classics",
//       listId: 502,
//       listURL: "/lists/502",
//     },
//     createdAt: "2024-09-10T13:50:00Z",
//     read: false,
//   },
//   {
//     id: 3,
//     type: "reply",
//     actors: ["David", "Emily", "Frank"],
//     target: {
//       type: "review",
//       bookTitle: "Moby Dick",
//       reviewId: 103,
//     },
//     createdAt: "2024-09-09T10:50:00Z",
//     read: false,
//   },
//   {
//     id: 4,
//     type: "like",
//     actors: ["Grace"],
//     target: {
//       type: "review",
//       bookTitle: "Pride and Prejudice",
//       reviewId: 104,
//     },
//     createdAt: "2024-09-09T09:30:00Z",
//     read: true,
//   },
//   {
//     id: 5,
//     type: "like",
//     actors: ["Harry", "Ivy"],
//     target: {
//       type: "review",
//       bookTitle: "War and Peace ",
//       reviewId: 105,
//     },
//     createdAt: "2024-09-09T08:45:00Z",
//     read: false,
//   },
//   {
//     id: 6,
//     type: "like",
//     actors: ["Jack", "Kara", "Liam"],
//     target: {
//       type: "review",
//       bookTitle: "To Kill a Mockingbird ",
//       reviewId: 106,
//     },
//     createdAt: "2024-09-08T07:30:00Z",
//     read: false,
//   },
//   {
//     id: 7,
//     type: "like",
//     actors: ["Mona", "Nathan", "Oscar", "Paul"],
//     target: {
//       type: "review",
//       bookTitle: "The Lord of the Rings",
//       reviewId: 107,
//     },
//     createdAt: "2024-09-10T18:00:00Z",
//     read: true,
//   },
//   {
//     id: 8,
//     type: "system",
//     message: "Your password has been reset successfully.",
//     createdAt: "2024-09-10T15:00:00Z",
//     read: true,
//   },
//   {
//     id: 9,
//     type: "system",
//     message: "Your reading challenge has been saved.",
//     createdAt: "2024-09-10T14:20:00Z",
//     read: false,
//   },
//   {
//     id: 10,
//     type: "reply",
//     replyContent: `While your enthusiasm for Harry Potter and the Chamber of Secrets is evident, it's important to consider the narrative depth beyond the surface level. Rowling weaves in subtle themes of prejudice, fear of the unknown, and the power of choice, which are crucial to understanding the characters' development and the broader implications of the story. Delving into these aspects can reveal a richer, more nuanced appreciation of the book that goes beyond its magical adventure.`,
//     actors: ["Quincy"],
//     target: {
//       type: "review",
//       bookTitle: "Brave New World",
//       reviewId: 108,
//     },
//     createdAt: "2024-09-10T13:40:00Z",
//     read: false,
//   },
//   {
//     id: 11,
//     type: "reply",
//     actors: ["Rachel", "Sam"],
//     target: {
//       type: "review",
//       bookTitle: "The Great Gatsby",
//       reviewId: 109,
//     },
//     createdAt: "2024-09-10T12:15:00Z",
//     read: true,
//   },
//   {
//     id: 12,
//     type: "reply",
//     actors: ["Tina", "Uma", "Victor"],
//     target: {
//       type: "review",
//       bookTitle: "Anna Karenina",
//       reviewId: 110,
//     },
//     createdAt: "2024-09-10T11:50:00Z",
//     read: false,
//   },
//   {
//     id: 13,
//     type: "like",
//     actors: ["Will"],
//     target: {
//       type: "review",
//       bookTitle: "Jane Eyre",
//       reviewId: 111,
//     },
//     createdAt: "2024-09-10T10:10:00Z",
//     read: false,
//   },
//   {
//     id: 14,
//     type: "like",
//     actors: ["Xander", "Yvonne"],
//     target: {
//       type: "review",
//       bookTitle: "Crime and Punishment",
//       reviewId: 112,
//     },
//     createdAt: "2024-09-09T16:45:00Z",
//     read: true,
//   },
//   {
//     id: 15,
//     type: "like",
//     actors: ["Zara", "Aaron", "Bella"],
//     target: {
//       type: "review",
//       bookTitle: "The Brothers Karamazov",
//       reviewId: 113,
//     },
//     createdAt: "2024-09-09T15:10:00Z",
//     read: false,
//   },
//   {
//     id: 16,
//     type: "like",
//     actors: ["Charlie", "Diana", "Eve", "Frank"],
//     target: {
//       type: "review",
//       bookTitle: "The Odyssey",
//       reviewId: 114,
//     },
//     createdAt: "2024-09-09T14:30:00Z",
//     read: true,
//   },
//   {
//     id: 17,
//     type: "system",
//     message: "Your list 'Top 10 Sci-Fi Books' was saved successfully.",
//     createdAt: "2024-09-09T13:20:00Z",
//     read: false,
//   },
//   {
//     id: 18,
//     type: "reply",
//     replyContent: `This is a classic. I loved it.`,
//     actors: ["George"],
//     target: {
//       type: "review",
//       bookTitle: "Les Misérables",
//       reviewId: 115,
//     },
//     createdAt: "2024-09-09T12:05:00Z",
//     read: true,
//   },
//   {
//     id: 19,
//     type: "reply",
//     actors: ["Hannah", "Isaac"],
//     target: {
//       type: "review",
//       bookTitle: "Dracula",
//       reviewId: 116,
//     },
//     createdAt: "2024-09-09T11:30:00Z",
//     read: true,
//   },
//   {
//     id: 20,
//     type: "reply",
//     actors: ["Jack", "Kara", "Liam", "Mona"],
//     target: {
//       type: "review",
//       bookTitle: "The Divine Comedy",
//       reviewId: 117,
//     },
//     createdAt: "2024-09-08T10:00:00Z",
//     read: false,
//   },
//   {
//     id: 21,
//     type: "like",
//     actors: ["Nathan"],
//     target: {
//       type: "review",
//       bookTitle: "Frankenstein",
//       reviewId: 118,
//     },
//     createdAt: "2024-09-08T09:45:00Z",
//     read: false,
//   },
//   {
//     id: 22,
//     type: "like",
//     actors: ["Oscar", "Paul"],
//     target: {
//       type: "review",
//       bookTitle: "The Iliad",
//       reviewId: 119,
//     },
//     createdAt: "2024-09-08T08:20:00Z",
//     read: false,
//   },
//   {
//     id: 23,
//     type: "system",
//     message: "Your email preferences have been updated successfully.",
//     createdAt: "2024-09-07T20:00:00Z",
//     read: true,
//   },
//   {
//     id: 24,
//     type: "system",
//     message: "Your account details have been updated.",
//     createdAt: "2024-09-07T18:15:00Z",
//     read: false,
//   },

//   {
//     id: 25,
//     type: "like_list",
//     actors: ["Alice", "Bob"],
//     target: {
//       type: "list",
//       listTitle: "Top 10 Sci-Fi Books",
//       listId: 501,
//       listURL: "/lists/501",
//     },
//     createdAt: "2024-09-10T14:20:00Z",
//     read: false,
//   },
//   {
//     id: 26,
//     type: "like_list",
//     actors: ["Charlie", "Diana", "Eve"],
//     target: {
//       type: "list",
//       listTitle: "Must-Read Classics",
//       listId: 502,
//       listURL: "/lists/502",
//     },
//     createdAt: "2024-09-10T13:50:00Z",
//     read: false,
//   },
// ]

const Notifications = ({
  variant = "popover"
}: {
  variant?: "popover" | "link";
}) => {
  if (variant === "link") {
    return <Link href="/notifications" className="relative flex h-11 w-11 cursor-pointer items-center justify-center rounded-full bg-white/5">
        <HiBell size={20} color="white" />
        <span className="sr-only">Notifications</span>
        {/* <div className="absolute -right-0 -top-0 inline-flex h-4 w-4 items-center justify-center rounded-full bg-[#5069ce] text-xs font-semibold text-white">
            6
          </div> */}
      </Link>;
  }
  return <Popover data-sentry-element="Popover" data-sentry-component="Notifications" data-sentry-source-file="Notifications.tsx">
      <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="Notifications.tsx">
        <div className="relative flex h-11 w-11 cursor-pointer items-center justify-center rounded-full bg-white/5">
          <HiBell size={20} color="white" data-sentry-element="HiBell" data-sentry-source-file="Notifications.tsx" />
          <span className="sr-only">Notifications</span>
          {/* <div className="absolute -right-0 -top-0 inline-flex h-4 w-4 items-center justify-center rounded-full bg-[#5069ce] text-xs font-semibold text-white">
            6
           </div> */}
        </div>
      </PopoverTrigger>
      <PopoverContent side="bottom" sideOffset={8} align="end" alignOffset={-86} className="h-[468px] w-[475px] rounded-[16px] border-[#bdbdbd]/10 bg-[#1d1e2f] p-0 pb-2 pt-6 text-white" data-sentry-element="PopoverContent" data-sentry-source-file="Notifications.tsx">
        <PopoverArrow className="h-5 w-10 rounded-b-[60px] fill-[#1d1e2f]" data-sentry-element="PopoverArrow" data-sentry-source-file="Notifications.tsx" />

        <div className="custom-dropdown-scrollbar h-full overflow-y-auto overflow-x-hidden">
          <div className="px-5 pb-4 text-sm font-medium text-[#e6e6fa]/90">
            NOTIFICATIONS
          </div>

          <div className="flex items-center h-5/6 justify-center">
            <p className="text-sm text-center text-[#e6e6fa]/80">
              No notifications available
            </p>
          </div>
          {/* {DUMMY_NOTIFICATIONS.slice(0, 0).map((notification) => (
            <NotificationCard
              key={notification.id}
              notification={notification}
              variant="navbar"
            />
           ))} */}

          {/* <PopoverClose asChild className="flex justify-center">
            <Link
              className="text-sm font-medium text-[#7187e1] hover:underline"
              href="/notifications"
            >
              See All
            </Link>
           </PopoverClose> */}
        </div>
      </PopoverContent>
    </Popover>;
};
export default Notifications;