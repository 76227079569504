import Image from "next/image";
import Link from "next/link";
import React from "react";
import { raleway } from "@/app/fonts";
import { cn } from "@/lib/utils";
const Logo = ({
  className,
  imageClassName,
  textClassName
}: {
  className?: string;
  imageClassName?: string;
  textClassName?: string;
}) => {
  return <Link href="/" className={cn(`${raleway.variable} w-fit flex items-center gap-[5.44px] sm:gap-2`, className)} data-sentry-element="Link" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
      <Image src="/icons/shared/logo-icon.svg" width={32} height={32} alt="kaguya logo" className={cn("h-5 w-5 sm:h-7 sm:w-7 rounded-[50%]", imageClassName)} data-sentry-element="Image" data-sentry-source-file="Logo.tsx" />
      <span className={cn(`font-raleway font-semibold text-white text-[23.56px] sm:text-[28px] leading:[29px] sm:leading-[35px]`, textClassName)}>
        Kaguya
      </span>
    </Link>;
};
export default Logo;